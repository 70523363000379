.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.landing-section {
  width: 100%;
  height: 100vh;
  margin: 0;
  background-color: #eef2fa;
}

.landing-section .landing-section-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
}

.why-us-section {
  width: 100%;
  height: 75vh;
  background-color: #eef2fa;
  display: flex;
  justify-content: center;
  z-index: 1;
  position: relative;
}

.why-us-section .why-us-section-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}


.landing-section video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.pricing {
  width: 100%;
  height: 100vh;
  background-color: #eef2fa;
  display: flex;
  justify-content: center;
  z-index: 1;
  position: relative;
}

.simulators {
  width: 100%;
  height: auto;
  min-height: 70vh;
  background-color: #3b4c6d;
  display: flex;
  justify-content: center;
  position: relative;
}

.review {
  width: 100%;
  height: fit-content;
  min-height: 80vh;
  background-color: #3b4c6d;
  display: flex;
  justify-content: center;
  position: relative;
}

.reviewer {
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-color: #3b4c6d;
  display: flex;
  justify-content: center;
  z-index: 1;
  position: relative;
}

.reviewer-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
}

.reviewer-option {
  background-color: #F5F5F5;
}

.reviewer-option-correct {
  background-color:  linear-gradient(to left, #F5F5F5 50%, lightblue 50%) right;
  background-size: 200%;
  transition: .5s ease-out;
  background-position: left;
}

.simulators .simulators-content {
  width: 100%;
  height: 98%;
  display: flex;
  justify-content: center;
  z-index: 1;
  position: relative;
  padding-top: 2%;
}

.footer {
  width: 100%;
  height: 20vh;
  color: #fff;
  background-color: #2b2d30;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
}


#customBtn {
  display: inline-block;
  background: white;
  color: #444;
  width: 190px;
  border-radius: 5px; 
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
}

#customBtn:hover {
  cursor: pointer;
}
span.label {
  font-family: serif;
  font-weight: normal;
}
span.icon {
  background: url('https://developers-dot-devsite-v2-prod.appspot.com/identity/sign-in/g-normal.png') transparent 5px  no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}

span.iconFB {
  background: url('https://truedatapublicassets.blob.core.windows.net/true-images/f_logo_RGB-Blue_58.png') transparent 5px 50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
  background-size: 30px, 30px;
}

span.buttonText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 42px;
  padding-right: 42px;
  font-size: 14px;
  font-weight: bold;
  /* Use the Roboto font that is loaded in the <head> */
  font-family: 'Roboto', sans-serif;
}

.change-outer {
  top: 0;
  left: 46%;
  text-align: left;
  overflow: hidden;
}

.change-outer .change-inner {
  position: relative;
  animation: rotate 8s ease-in-out infinite;
}

.change-outer .element {
  display: block;
  font-weight: 700;
  text-shadow: 2px 2px 2px #282c34;
}

@keyframes rotate {
  0%, 20% {
    transform: translateY(0);
  }
  25%,45% {
    transform: translateY(50 * -1.5);
  }
  50%,70% {
    transform: translateY(50 * -3);
  }
  75%,95% {
    transform: translateY(50 * -4.5);
  }
  100% {
    transform: translateY(50 * -6);
  }
}